import React, { Fragment } from 'react'
import Slider from 'react-slick'
import { check, checkUrl } from '../../utils/stringUtils'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './slickStyles.scss'
import { ThemeProvider } from 'styled-components'
import {
  GalleryContainer,
  ImagesContainer,
  GalleryImageContainer,
  GalleryImage,
  Title,
  DescriptionText,
  InnerContainer,
  ContentContainer,
  GalleryOuterContainer,
  GlobalContainer,
  StaticContainer,
  OuterContainer,
  Dark,
  Light,
} from './styles'
import Button from '../button/button'
import { onClickHandler } from '../../utils/url'
import { CTA } from '../basic-content-container/styles'

const LIGHT = 'Light'
const DARK = 'Dark'

class Gallery extends React.Component {
  constructor(props) {
    super(props)

    const { style } = props
    let theme

    if (style === LIGHT) {
      theme = Light
    } else {
      theme = Dark
    }

    this.state = {
      theme: theme,
      darkBackground: theme !== LIGHT,
    }
  }

  getContent = (description, richContent) => {
    if(richContent && richContent.html){
      // console.log('richContent', richContent);
    } else {
      // console.log('NOT DETECTED richContent', richContent);
    }
    
    return richContent && richContent.html ? (
      <div dangerouslySetInnerHTML = { { __html: richContent.html } } />
    ) : (
      <span>{ description.text }</span>
    )
  }

  renderImage({
    index,
    description,
    rich_description,
    image,
    title1,
    link,
    link_title,
    flip,
  }) {
    return (
      <div key = { `${index}-${title1}` }>
        <InnerContainer>
          <ContentContainer flip = { flip }>
            <Title style = { { marginBottom: '20px' } }>{ title1.text }</Title>
            <DescriptionText>
              { this.getContent(description, rich_description) }
            </DescriptionText>
            { checkUrl(link) && check(link_title) && (
              <div>
                <Button
                  text = { link_title.text }
                  onClickHandler = { () => onClickHandler(link.url) }
                  black = { this.state.theme.background !== '#000' }
                />
              </div>
            ) }
          </ContentContainer>
          <GalleryOuterContainer flip = { flip }>
            <GalleryImageContainer>
              <GalleryImage
                key = { `gallery_${index}` }
                src = { image.url }
                alt = { image.alt }
              />
            </GalleryImageContainer>
          </GalleryOuterContainer>
        </InnerContainer>
      </div>
    )
  }

  onGalleryClick = () => {
    this.slider.slickNext()
  }

  renderSlideshow() {
    const { use_dots, body, flip } = this.props

    const items = body.length > 0 ? body[0].items : []
    const slickSettings = {
      dots: use_dots === 'True',
      arrows: false,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <OuterContainer flip = { flip } onClick = { this.onGalleryClick }>
        <Slider ref = { slider => (this.slider = slider) } { ...slickSettings }>
          { items.map(
            (
              {
                title1,
                description,
                rich_description,
                image,
                link,
                link_title,
              },
              index
            ) =>
              this.renderImage({
                index,
                description,
                rich_description,
                image,
                title1,
                link,
                link_title,
                flip,
              })
          ) }
        </Slider>
      </OuterContainer>
    )
  }

  renderStatic() {
    const { body } = this.props

    const items = body.length > 0 ? body[0].items : []

    return (
      <StaticContainer>
        { items.map(({ image }, index) => (
          <GalleryImage
            key = { `gallery_${index}` }
            src = { image.url }
            alt = { image.alt }
          />
        )) }
      </StaticContainer>
    )
  }

  render() {
    const { title, content, layout, flip, tag } = this.props

    return (
      <ThemeProvider theme = { this.state.theme }>
        <GalleryContainer
          darkBackground = { this.state.darkBackground }
          value = { tag && tag.text }
        >
          <GlobalContainer>
            { title && title.text && <Title>{ title.text }</Title> }
            <ImagesContainer>
              { layout === 'Normal'
                ? this.renderStatic()
                : this.renderSlideshow() }
            </ImagesContainer>
          </GlobalContainer>
        </GalleryContainer>
      </ThemeProvider>
    )
  }
}

export default Gallery
