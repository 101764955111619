import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

import { MOBILE_SIDE_PADDING } from '../../styles/eversys';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

/*
 * Themes.
 * -
 * These are determined by the 'style' value of the component.
 */
export const Light = {
  background: "#F0F0F0",
  color: "#000",
  imagePadding: "20px 20px",
  subtextColor: "#000",
};

export const Dark = {
  background: "#000",
  color: "#FFF",
  imagePadding: "0",
  subtextColor: "#FFF",
};

/*
 * Styles
 */

export const GalleryContainer = styled.section`
  background: ${props => props.theme.background};
  padding: 40px ${MOBILE_SIDE_PADDING};
  
  .slick-dots li button {
      background-color: #CCC !important; 
      opacity: .5;
      border: 0;
    }
    
    ${breakpoint('tablet')`
      padding-bottom: 100px;
    `};
  
  ${({ darkBackground }) => darkBackground && `
    .slick-dots li button {
      background-color: #F0F0F0; 
      opacity: .5;
    }
    
    .slick-dots li.slick-active button {
      background-color: #FFF; 
      opacity: 1;
    }
  `}
`;

export const GlobalContainer = styled.section`
  ${breakpoint('tablet')`
    max-width:${DESKTOP_MAX_WIDTH};
    margin: 0 auto;
  `};
`;

export const InnerContainer = styled.section`
  ${breakpoint('tablet')`
    display: flex;
    align-items: center;
  `};
`;

export const ContentContainer = styled.section`
  ${breakpoint('tablet')`
    flex: 1;
    padding: 50px 0 0 40px;
    
    ${({ flip }) => flip === 'Yes' && `
      order: 2;
      position: relative;
      
      .slick-dots {
        text-align: left !important;
        margin-right: 30px !important;
        position: absolute;
        left: 50%;
      }
    `}
  `};
`;

export const OuterContainer = styled.section`
  ${breakpoint('tablet')`
    ${({ flip }) => flip === 'Yes' && `      
      .slick-dots {
        text-align: left !important;
        margin-right: 30px !important;
        position: absolute;
        left: 50%;
        margin-left: 15px !important;
      }
    `}
  `};
`;

export const GalleryOuterContainer = styled.section`
  margin-top: 10px; 
  
  ${breakpoint('tablet')`
    flex: 1;
    
    ${({ flip }) => flip === 'Yes' && `
      order: 1;
    `}
  `};
`;

export const Title = styled.h1`
  margin: 0;
  /* text-align: center; */
  color: ${props => props.theme.subtextColor};
`;

export const DescriptionText = withReveal(styled.div`
  font-size: 14px;
  margin: 0 0 20px;
  display: block;
  color: ${props => props.theme.subtextColor};
`, <Fade />);

export const ImagesContainer = styled.section`
  padding: 20px 0;
`;

export const GalleryImageContainer = styled.span`
  height: 300px;
  width: 100%;
  display: block;
  
  ${breakpoint('tablet')`
    height: 450px;
  `};
`;

export const GalleryImage = styled.img`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const StaticContainer = styled.div`
  ${breakpoint('tablet')`
    column-count: 2;
  `}
`;
