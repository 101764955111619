import React from 'react';
import ComponentMappings from './prismicComponentMappings';

const getComp = name => ComponentMappings.filter(obj => obj.prismicKey === name);

export const renderComponent = (component) => {
  if(!component || !component.type) return;

  const comp = getComp(component.type);

  /* Check we have a react component to use for that component model */
  if (comp && comp.length > 0) {
    const template = comp[0].component;

    return React.createElement(template, {
      ...component.document[0].data,
      key: `EV${Math.random()}`,
    });
  }
};
