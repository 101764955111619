import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

import { MOBILE_SIDE_PADDING } from '../../styles/eversys';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';
import {
  BASIC_GREY_LAYOUT,
  BASIC_LAYOUT,
  FULL_BACKGROUND_LAYOUT,
  FULL_BACKGROUND_WITH_CTA_LAYOUT,
} from './basicContentContainer';

/*
 * Themes.
 * -
 * These are determined by the 'layout' value of the component.
 * TODO: Refactor these to purely colour themes. Light and dark.
 */
export const BasicLayoutTheme = {
  background: "#FFF",
  color: "#000",
  imagePadding: "20px 20px",
  contentOrder: 1,
  imageOrder: 2,
};

export const BasicGreyLayoutTheme = {
  background: "#CCC",
  color: "#000",
  imagePadding: "0 20px",
  contentOrder: 1,
  imageOrder: 2,
};

export const FullBackgroundLayoutTheme = {
  background: "#FFF",
  color: "#000",
  imagePadding: "0",
};

export const FullBackgroundCTALayoutTheme = {
  background: "#FFF",
  color: "#000",
  imagePadding: "30px 0 0"
};

export const InvertedBasicLayoutTheme = {
  background: "#000",
  color: "#FFF",
  imagePadding: "20px 20px",
};

export const BasicLayoutLeftTheme = {
  background: "#000",
  color: "#FFF",
  imagePadding: "20px 20px",
};

/*
 * Styles
 */
export const Container = styled.section`
  width: 100%;
  background: ${props => props.theme.background};
  padding: 20px 0 0;
  color: ${props => props.theme.color};
  
  ${breakpoint('tablet')`
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => props.backgroundImage};
    
    ${({ forceColumn }) => forceColumn && `
      padding: 20px !important;
    `}
    
    ${({ layout }) => (layout === BASIC_LAYOUT || layout === BASIC_GREY_LAYOUT) && `
      padding: 80px 20px;
    `};
    
    ${({ layout }) => (layout === FULL_BACKGROUND_LAYOUT || layout === FULL_BACKGROUND_WITH_CTA_LAYOUT) && `
      padding: 60px 20px;
    `};
  `}
`;

export const Heading = withReveal(styled.h1`
  padding: 5px ${MOBILE_SIDE_PADDING};
  margin-bottom: 0;
`, <Fade />);
export const Content = withReveal(styled.div`
  padding: 5px ${MOBILE_SIDE_PADDING} 0px;
  margin-bottom: 30px;
  line-height: 26px;
`, <Fade />);

export const Image = withReveal(styled.img`
  max-width: 100%;
  width: 100%;
  padding: ${props => props.theme.imagePadding};
`, <Fade delay = { 100 } />);

export const CTA = styled.span`
  display: block;
  margin-top: 10px;
  padding: 10px ${MOBILE_SIDE_PADDING} 20px;
`;

export const InnerContainer = styled.span`
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    display: flex;
    margin: 50px auto;
    align-items: center;
    width: 100%;
    
    ${({ simpleElement }) => simpleElement && `
      margin: 0 auto 20px;
    `}
    
    ${({ forceColumn }) => forceColumn && `
      flex-direction: column;
    `}
    
    ${({ layout }) => layout === FULL_BACKGROUND_LAYOUT && `
      justify-content: flex-end;
    `}
    
    ${({ layout }) => layout === FULL_BACKGROUND_WITH_CTA_LAYOUT && `
      justify-content: flex-start;
    `}
  `};
`;

export const ContentContainer = styled.span`
  flex: 1;
  order: ${props => props.theme.contentOrder};
  
  ${breakpoint('tablet')`
    width: 100%;
    
    ${({ layout, simpleElement }) => (layout === FULL_BACKGROUND_LAYOUT || layout === FULL_BACKGROUND_WITH_CTA_LAYOUT) && !simpleElement && `
      flex: 1 1 50%;
      background: #FFF;
      padding: 30px 10px;
      max-width: 45%;
      box-shadow: 0px 0px 55px 2px rgba(0,0,0,0.22);
      min-width: 300px;
    `};
    
    ${({ layout, simpleElement }) => (layout === FULL_BACKGROUND_LAYOUT || layout === FULL_BACKGROUND_WITH_CTA_LAYOUT) && simpleElement && `
      text-align: center;
      margin: 0 auto;
    `};

    ${({ imageLeft }) => imageLeft && `
      order: 2;
    `};
    
    ${({ simpleElement }) => simpleElement && `
      text-align: center;
      margin: 0 auto;
      padding: 0 10px;
    `};
  `}
`;

export const ImageContainer = styled.span`
  flex: 1;
  order: ${props => props.theme.imageOrder};

  ${breakpoint('tablet')`
     width: 100%;
  `};
`;
