import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const RichTextContainer = styled.section`
  width: 100%;
  padding: 30px;
  background: #FFF;
`;

export const ContentContainer = styled.article`  
  ${breakpoint('tablet')`
    max-width: ${DESKTOP_MAX_WIDTH};
    margin: auto;
    
    column-count: ${props => props.columnCount};
  `};
`;

export const Heading = withReveal(styled.h1`
  ${breakpoint('tablet')`
    text-align: center;
  `};
`, <Fade delay = { 100 } />);
export const Content = withReveal(styled.div`
  margin-top: 40px;
  max-width: 90%;
  text-align: left;
  
  ${breakpoint('tablet')`
    max-width: 100%;
  `}
`, <Fade delay = { 200 } />);
